import '/scss/style.scss';
import * as jQuery from 'jquery';

(function($) {

    //MENU HAMBURGER

    let menuHamburger = $(".menu_hamburger");
        menuHamburger.hide();
    let iconHamburger = $(".hamburger");

    iconHamburger.on("click", function() {
        iconHamburger.toggleClass("active");
        menuHamburger.slideToggle();
    });

    //GALLERY

	let gallery = $(".set_gallery");
    let buttons = $(".buttons");
    let close = $(".close");
        close.hide();
    
    buttons.on("click", function(){

        let image = $(".image");
        let filter = $(this).attr("data-filter");

        $(this).addClass("active").siblings().removeClass("active");

        if (filter == "all"){
            image.show(400);            
        } else {
            image.not("."+filter).hide(200);
            image.filter("."+filter).show(400);
        };
    });

    //OVERLAY
    let bodyGallery = (".gallery");
    let overlay = $("<div/>", {id: "overlay"});
        overlay.appendTo(bodyGallery).hide();

    gallery.find("a").on("click", function(event) {
        
        let href = $(this).attr("href"),
            image = $("<img>", {src: href, alt: "obrazok"});
            close.show();
                     
        overlay.html(image).show();
               
        event.preventDefault();
    });

        close.on("click", function() {
            close.hide();
            overlay.hide();
    });

    $(document).on("keyup", function(event) {
        if (event.which === 27) overlay.hide();
    });
    
})(jQuery);

    